<template>
  <div>
    <UserHeader />
    <router-view />
    <!-- <UserFooter /> -->
  </div>
</template>

<script>
import UserHeader from "@/components/UserHeader";
// import UserHeader from "./components/AdminUserHeader"
// import UserFooter from "@/components/UserFooter";

export default {
  name: "app",
  components: {
    UserHeader,
    // UserFooter,
  },
  methods: {
    setMeta(route) {
      // ページタイトルを設定
      if (route.meta.title) {
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      // ディスクリプションを設定
      if (route.meta.desc) {
        let setDesc = route.meta.desc;
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", setDesc);
      }
    },
  },
  mounted() {
    let route = this.$route;
    this.setMeta(route);
  },
  watch: {
    $route(route) {
      this.setMeta(route);
    },
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/common.scss";
</style>
