import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import store from './store'//追記
import axios from "axios"; //追記
import VueAxios from "vue-axios"; //追記
import VueConfig from "./vue.config";
import VueGTag from "vue-gtag";

VueConfig.productionTip = false;

const app = createApp(App);
app.use(VueAxios, axios);
app.use(
  VueGTag,
  {
    config: {
      id: "{ G-2VHRP4GWZR }",
    },
  },
  router
);
app.use(router);
// app.use(store)
app.mount("#app");
