import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { title: 'Apoppanet', desc: 'Apoplanetトップ' }
  },
  // {
  //   path: '/event-list',
  //   name: 'event-list',
  //   // component: EventList
  //   component: () => import('./views/event/EventList.vue'),
  //   meta: { title: 'イベント一覧', desc: 'Apoplanet イベント一覧' }
  // },
  // {
  //   path: '/event-detail',
  //   name: 'event-detail',
  //   // component: EventDetail
  //   component: () => import('./views/event/EventDetail.vue'),
  //   meta: { title: 'イベント詳細', desc: 'Apoplanet イベント詳細' }
  // },
  // {
  //   path: '/event-entry-solo',
  //   name: 'event-entry-solo',
  //   // component: EventEntrySolo
  //   component: () => import('./views/event/EventEntrySolo.vue'),
  //   meta: { title: 'イベントエントリーソロ', desc: 'Apoplanet イベントエントリーソロ' }
  // },
  // {
  //   path: '/event-entry-team',
  //   name: 'event-entry-team',
  //   // component: EventEntryTeam
  //   component: () => import('./views/event/EventEntryTeam.vue'),
  //   meta: { title: 'イベントエントリーチーム', desc: 'Apoplanet イベントエントリーチーム' }
  // },
  // {
  //   path: '/event-entry-spectating',
  //   name: 'event-entry-spectating',
  //   // component: EventEntrySpectating
  //   component: () => import('./views/event/EventEntrySpectating.vue'),
  //   meta: { title: 'イベント観戦エントリー', desc: 'Apoplanet イベント観戦エントリー' }
  // },
  // {
  //   path: '/event-entry-complete',
  //   name: 'event-entry-complete',
  //   // component: EventEntryComplete
  //   component: () => import('./views/event/EventEntryComplete.vue'),
  //   meta: { title: 'エントリー完了', desc: 'Apoplanet エントリー完了' }
  // },
  // {
  //   path: '/event-entry-list',
  //   name: 'event-entry-list',
  //   // component: EventEntryList
  //   component: () => import('./views/event/EventEntryList.vue'),
  //   meta: { title: 'イベントエントリー一覧', desc: 'Apoplanet イベントエントリー一覧' }
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
