<template>
    <div>
        <header id="header" class="l-header">
            <div class="l-logo">
                <a href="/" class="lnk-logo"><img src="@/assets/user/logo.svg" alt="Apoplanet" class="img-logo"></a>
            </div>
            <div class="l-header-sp">
                <div id="hamburger" class="l-hamburger">
                    <span class="line"></span>
                    <span class="line"></span>
                </div>
            </div>
            <nav id="header-menu" class="l-header-menu">
                <!-- <ul class="ls-header-menu-dtl"> -->
                <!-- <div class="header-menu-dtl">
                    <a class="lnk-menu" href="/news-list">NEWS</a>
                </div> -->
                <!-- <div class="header-menu-dtl">
                    <router-link to="/event-list" class="lnk-menu">EVENT</router-link>
                </div> -->
                <!-- </ul> -->
            </nav>
        </header>
        <div class="nav-overray" id="overRay"></div>
    </div>
</template>

<script>
window.onload = function() {
    var hamburger = document.getElementById("hamburger");
    var menu = document.querySelector(".l-header-menu");
    var overRay = document.getElementById("overRay");
    var flg = false;
    var scrollpos;
    hamburger.addEventListener('click', function(){
    (this).classList.toggle('active');
    menu.classList.toggle('open');
    overRay.classList.toggle('display');
    console.log('clicked');
    if (!flg) {
        scrollpos = document.scrollingElement.scrollTop;
        console.log(scrollpos);
        document.body.classList.add('fixed');
        document.querySelector('.fixed').style.top = -scrollpos + 'px';
        flg = true;
    } else {
        document.body.classList.remove('fixed');
        window.scrollTo(0, scrollpos);
        console.log('closed', scrollpos);
        flg = false;
    }
    });
}
</script>