<template>
    <section id="eventData" class="l-event">
        <div class="wrap">
            <h2 class="ttl">Event Calender</h2>
            <FullCalendar :options="calendarOptions" />
            <!-- <ul class="ls-event">
                <li class="l-event-dtl" v-for="event in PostsCount" :key="event.event_name">
                    <a href="/event-detail" class="event-dtl-item-wrap">
                        <figure class="l-img">
                            <img src="@/assets/user/img-sample@2x.jpg" alt="" class="img">
                        </figure>
                        <div class="event-dtl-item">
                            <h3 class="event-ttl">{{event.event_name}}</h3>
                            <p class="event-date">{{event.event_date}}</p>
                            <p class="event-place">{{event.event_place}}</p>
                            <ul class="event-tags">
                                <li class="tag" v-for="category in event.category" :key="category" v-bind:class="category.toLowerCase">{{category}}</li>
                            </ul>
                        </div>
                    </a>
                    <EventContent/>
                </li>
            </ul>
            <div class="l-btn">
                <router-link to="/event-list" class="btn-secondary">イベント一覧を見る</router-link>
            </div> -->
        </div>
    </section>
</template>

<script>

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, googleCalendarPlugin, bootstrap5Plugin ],
        themeSystem: 'bootstrap5',
        initialView: 'dayGridMonth',
        locale:'ja',
        timeZone: "Asia/Tokyo", //念のため
        googleCalendarApiKey: 'AIzaSyDqAQnz75f3LEpFz1JbetYMt7Fx4H1t1_w',
        eventSources: [
            //APOPイベント
            {
                googleCalendarApiKey: 'AIzaSyDqAQnz75f3LEpFz1JbetYMt7Fx4H1t1_w',
                googleCalendarId: 'info@apoplanet.net',
            },
            //祝日カレンダー
            {
                googleCalendarId: 'ja.japanese#holiday@group.v.calendar.google.com',
                className: 'holiday',
                textColor: '#ffffff',
                backgroundColor: 'red',
                borderColor: '#ffffff00'
            }
        ],
        height: "100vh",
        headerToolbar: {
            start: 'prev,title,next', // will normally be on the left. if RTL, will be on the right
            center: '',
            end: 'dayGridMonth,timeGridWeek,timeGridDay today', // will normally be on the right. if RTL, will be on the left
        },

        businessHours: true,//土日の色付け

        dayMaxEventRows:true,
        eventMaxStack:3,
        navLinks: true,//日付クリックしたら日の時間表示に変わるよ
        eventClick: function(info) {
            console.log(info);
            console.log(info.event);
            info.jsEvent.preventDefault(); // don't let the browser navigate
        },
        eventDidMount:function(info){
            return new bootstrap.Popover(info.el, {
                title: info.event.title,
                placement: "auto",
                trigger: "click",
                customClass: "popoverStyle",
                content:function() {
                    // let start = data.instance.range.start;
                    // console.log(start);
                    let location = info.event.extendedProps.location;
                    if (location == null) {
                        return '未設定';
                    }
                    let description = info.event.extendedProps.description;
                    if (description == null) {
                        return '未設定';
                    }
                    return '<h2 class="calendar-ttl">場所</h2>'+'<p class="calendar-txt">'+location+'</p>'+'<h2 class="calendar-ttl">詳細</h2>'+'<p class="calendar-txt">'+description+'</p>';
                },
                html: true,
            });
        },

        dayCellContent: function (e) {
            return e.date.getDate();//カレンダー内の日を削除例：15日を15
        },
        dayHeaderFormat: function (date) {
            const days = ["日", "月", "火", "水", "木", "金", "土"];
            return days[date.date.marker.getDay()];
        },

        buttonText:{
            today:    '今日',
            month:    '月',
            week:     '週',
            day:      '日',
            list:     '一覧',
        }
      }
    }
  }
}

// // import EventContent from "@/components/User/EventContent"
// //axiosを使う場合
// // import axios from 'axios'
// //ローカルでの挙動確認の場合
// import eventList from"@/views/eventList.json"
// export default {
//     name: 'ApoplanetHome',
//     // components: {EventContent},
//     //ローカルでの挙動確認の場合
//     data(){
//         // eventLink:'/event-detail'
//         return{
//             // event:[],
//             // eventLink:'/event-detail',
//             eventList
//         }
//     },
//     computed:{
//         PostsCount(){
//             return this.eventList.eventData.slice(0, 8)
//         }
//     },
//     // filters:{
//     //     lowerCase(value){
//     //         return value.tolowerCase
//     //     }
//     // },
//     mounted(){
//         // for (var i = 0; i > 9; i ++) {
//         //     console.log(eventList.eventData[i].event_name);
//         // }

//         console.log(eventList.eventData);
//         //axiosを使う場合
//         // var self = this;
//         // axios
//         // .get('@/views/eventList.json')
//         // .then(
//         //     // response => console.log(response)
//         //     function(response) {
//         //         self.eventData = response.data.eventData;
//         //     }
//         // )
//         // .catch(function(error) {
//         //     console.log('取得に失敗しました。', error);
//         // })
//         // console.log("終わり");
//     }
// }

</script>

<style lang="scss">
    // @import "@/assets/scss/common.scss";
    @import "@/assets/scss/page/event-list.scss";
</style>